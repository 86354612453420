/* eslint-disable */ import * as types from './graphql';
/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */ let documents = {
    'subscription SubscribeConfigInfo {\n  config(order_by: {key: asc}, where: {key: {_nlike: "llm-provider.%"}}) {\n    key\n    value\n  }\n}': types.SubscribeConfigInfoDocument,
    "mutation UpdateGlobalConfig($key: String!, $value: String!) {\n  update_config_by_pk(pk_columns: {key: $key}, _set: {value: $value}) {\n    key\n  }\n}": types.UpdateGlobalConfigDocument,
    'subscription SubscribeWorldList {\n  world(order_by: [{enabled: asc}, {created_at: asc}, {id: asc}]) {\n    id\n    enabled\n    name: config(path: "name")\n    world_template {\n      id\n      name: config(path: "name")\n    }\n    created_at\n  }\n}': types.SubscribeWorldListDocument,
    "query QueryWorldBasicState($id: bpchar!) {\n  world_by_pk(id: $id) {\n    id\n    enabled\n    created_at\n    season {\n      id\n    }\n  }\n}": types.QueryWorldBasicStateDocument,
    "mutation UpdateWorldEnabledState($id: bpchar!, $enabled: Boolean!) {\n  update_world_by_pk(pk_columns: {id: $id}, _set: {enabled: $enabled}) {\n    enabled\n  }\n}": types.UpdateWorldEnabledStateDocument,
    "query QueryWorldConfig($id: bpchar!) {\n  world_by_pk(id: $id) {\n    id\n    config\n    world_template {\n      id\n      config\n    }\n  }\n}": types.QueryWorldConfigDocument,
    'subscription SubscribeWorldOptionList {\n  world(order_by: [{enabled: asc}, {created_at: desc}], limit: 100) {\n    id\n    enabled\n    name: config(path: "name")\n  }\n}': types.SubscribeWorldOptionListDocument,
    "subscription SubscribeWorldPlayerList($world: bpchar!) {\n  player(where: {world_id: {_eq: $world}}, order_by: [{created_at: asc}, {id: asc}]) {\n    id\n    data\n    enabled\n    team\n  }\n}": types.SubscribeWorldPlayerListDocument,
    "mutation UpdateWorldConfig($id: bpchar!, $config: jsonb!) {\n  update_world_by_pk(pk_columns: {id: $id}, _append: {config: $config}) {\n    id\n  }\n}": types.UpdateWorldConfigDocument,
    "mutation InsertWorldFromTemplate($id: bpchar!, $template_id: bpchar!) {\n  insert_world_one(object: {id: $id, enabled: false, template_id: $template_id, config: {}}) {\n    created_at\n  }\n}": types.InsertWorldFromTemplateDocument,
    "mutation DeleteWorldById($id: bpchar!) {\n  delete_world_by_pk(id: $id) {\n    id\n  }\n}": types.DeleteWorldByIdDocument,
    "subscription SubscribeWorldTemplateList {\n  world_template(order_by: {created_at: asc}) {\n    id\n    config\n    created_at\n  }\n}": types.SubscribeWorldTemplateListDocument,
    "query QueryWorldTemplateList {\n  world_template(order_by: {created_at: asc}) {\n    id\n    config\n    created_at\n  }\n}": types.QueryWorldTemplateListDocument,
    "mutation InsertWorldTemplate($id: bpchar!, $config: jsonb = {}) {\n  insert_world_template_one(object: {id: $id, config: $config}) {\n    id\n  }\n}": types.InsertWorldTemplateDocument,
    "mutation DeleteWorldTemplate($id: bpchar!) {\n  delete_world_template_by_pk(id: $id) {\n    id\n  }\n}": types.DeleteWorldTemplateDocument,
    "mutation UpdateWorldTemplate($id: bpchar!, $config: jsonb!) {\n  update_world_template_by_pk(pk_columns: {id: $id}, _set: {config: $config}) {\n    id\n  }\n}": types.UpdateWorldTemplateDocument,
    "mutation GenerateWorldTemplateFromWorld($new_id: bpchar!, $world_id: bpchar!) {\n  generate_template_from_world(args: {new_id: $new_id, world_id: $world_id}) {\n    id\n  }\n}": types.GenerateWorldTemplateFromWorldDocument,
    "mutation GenerateWorldTemplateFromWorldAndSetId($new_id: bpchar!, $world_id: bpchar!) {\n  generate_template_from_world(args: {new_id: $new_id, world_id: $world_id}) {\n    id\n  }\n  update_world_by_pk(pk_columns: {id: $world_id}, _set: {template_id: $new_id}) {\n    id\n  }\n}": types.GenerateWorldTemplateFromWorldAndSetIdDocument,
    "mutation UpdateWorldTemplateIdForWorld($id: bpchar!, $template_id: bpchar) {\n  update_world_by_pk(pk_columns: {id: $id}, _set: {template_id: $template_id}) {\n    id\n  }\n}": types.UpdateWorldTemplateIdForWorldDocument,
    "query QuerySeasonList {\n  season(order_by: [{start_at: desc}, {end_at: desc}, {id: asc}], where: {open: {_eq: true}}) {\n    id\n    world {\n      enabled\n    }\n    world_template {\n      id\n      name: config(path: \"name\")\n    }\n    name: config(path: \"name\")\n    start_at\n  }\n}": types.QuerySeasonListDocument,
    "mutation InsertSeason($id: bpchar!, $template_id: bpchar!, $visible_at: timestamptz!, $start_at: timestamptz!, $end_at: timestamptz!, $shape: jsonb!) {\n  insert_season_one(object: {id: $id, config: {}, template_id: $template_id, visible_at: $visible_at, start_at: $start_at, end_at: $end_at}) {\n    id\n  }\n  create_season_tickets(args: {season_id: $id, shape: $shape}) {\n    ticket_id\n  }\n}": types.InsertSeasonDocument,
    "query QuerySeasonInfo($id: bpchar!) {\n  season_by_pk(id: $id) {\n    id\n    config\n    template: world_template {\n      id\n      config\n    }\n    start_at\n    visible_at\n    created_at\n    end_at\n    world_id\n  }\n}": types.QuerySeasonInfoDocument,
    "mutation UpdateSeasonInfo($id: bpchar!, $config: jsonb!, $start_at: timestamptz!, $end_at: timestamptz!, $visible_at: timestamptz!, $template_id: bpchar!) {\n  update_season_by_pk(pk_columns: {id: $id}, _append: {config: $config}, _set: {start_at: $start_at, end_at: $end_at, visible_at: $visible_at, template_id: $template_id}) {\n    id\n  }\n}": types.UpdateSeasonInfoDocument,
    "mutation DeleteSeason($id: bpchar!) {\n  delete_season_by_pk(id: $id) {\n    id\n  }\n}": types.DeleteSeasonDocument,
    'subscription SubscribeDirectorState($id: bpchar!) {\n  world_by_pk(id: $id) {\n    director_state\n    script: merged_config(path: "directorScript")\n  }\n}': types.SubscribeDirectorStateDocument,
    "mutation UpdateDirectorState($id: bpchar!, $director_state: jsonb!) {\n  update_world_by_pk(pk_columns: {id: $id}, _set: {director_state: $director_state}) {\n    id\n  }\n}": types.UpdateDirectorStateDocument,
    "subscription SubscribeWorldDirectorLog($id: String!) {\n  world_director_log(where: {world_id: {_eq: $id}}, order_by: {created_at: desc}) {\n    id\n    changed\n    created_at\n  }\n}": types.SubscribeWorldDirectorLogDocument,
    'query QeuryLLMTokenLog($world: String!) {\n  llm_token_log(where: {world: {_eq: $world}}, order_by: {created_at: desc}, limit: 50) {\n    id\n    player_info {\n      id\n      name: data(path: "name")\n    }\n    source\n    type\n    tokens\n    llm_log {\n      input\n      output\n      description\n    }\n    created_at\n  }\n  total: llm_token_log_aggregate(where: {world: {_eq: $world}}) {\n    aggregate {\n      sum {\n        tokens\n      }\n    }\n  }\n  converse: llm_token_log_aggregate(where: {world: {_eq: $world}, type:{_eq:"converse"}}) {\n    aggregate {\n      sum {\n        tokens\n      }\n    }\n  }\n  introspection: llm_token_log_aggregate(where: {world: {_eq: $world}, type:{_eq:"introspection"}}) {\n    aggregate {\n      sum {\n        tokens\n      }\n    }\n  }\n}': types.QeuryLlmTokenLogDocument,
    "query GetFullMissionList {\n  mission(order_by: {sort: {sort_order: asc_nulls_last}}) {\n    id\n    description\n    sort {\n      sort_order\n      type\n    }\n  }\n}": types.GetFullMissionListDocument,
    "mutation UpdateMissionData($id: bpchar!, $data: String!, $description: jsonb!) {\n  update_mission_by_pk(pk_columns: {id: $id}, _set: {data: $data}, _append: {description: $description}) {\n    updated_at\n  }\n}": types.UpdateMissionDataDocument,
    "mutation UpdateMissionDescription($id: bpchar!, $description: jsonb!) {\n  update_mission_by_pk(pk_columns: {id: $id}, _append: {description: $description}) {\n    updated_at\n  }\n}": types.UpdateMissionDescriptionDocument,
    "mutation InsertMission($data: String!, $description: jsonb!) {\n  insert_mission_one(object: {data: $data, description: $description}) {\n    id\n  }\n}": types.InsertMissionDocument,
    "mutation PublishMission($id: bpchar!, $type: String!) {\n  publish_mission(args: {id: $id, type: $type}) {\n    id\n  }\n}": types.PublishMissionDocument,
    "mutation ReorderMission($ids: jsonb!) {\n  reorder_mission(args: {ids: $ids}) {\n    sort_order\n  }\n}": types.ReorderMissionDocument,
    "mutation UnpublishMission($id: bpchar!) {\n  delete_mission_sort_by_pk(id: $id) {\n    id\n  }\n}": types.UnpublishMissionDocument,
    "mutation UpdateMissionType($id: bpchar!, $type: String!) {\n  update_mission_sort_by_pk(pk_columns: {id: $id}, _set: {type: $type}) {\n    id\n  }\n}": types.UpdateMissionTypeDocument,
    "query ListItemByType($type: String!) {\n  item_definition(where: {type: {_eq: $type}}, order_by: {id: asc}) {\n    id\n    description\n  }\n}": types.ListItemByTypeDocument,
    "mutation CreateItemDefinition($id: String!, $description: jsonb!) {\n  insert_item_definition_one(object: {id: $id, description: $description}) {\n    id\n  }\n}": types.CreateItemDefinitionDocument,
    "mutation UpdateItemDefinition($id: String!, $description: jsonb!) {\n  update_item_definition_by_pk(pk_columns: {id: $id}, _set: {description: $description}) {\n    id\n  }\n}": types.UpdateItemDefinitionDocument,
    "mutation DeleteItemDefinition($id: String!) {\n  delete_item_definition_by_pk(id: $id) {\n    id\n  }\n}": types.DeleteItemDefinitionDocument,
    "query ListDisabledCustomizeStore {\n  customize_store(where: {order: {_is_null: true}}) {\n    id\n    data\n  }\n}": types.ListDisabledCustomizeStoreDocument,
    "mutation CreateCustomizeStoreItem($id: String!, $data: jsonb!) {\n  insert_customize_store_one(object: {id: $id, data: $data}) {\n    id\n  }\n}": types.CreateCustomizeStoreItemDocument,
    "mutation EditCustomizeItem($id: String!, $_set: customize_store_set_input!) {\n  update_customize_store_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n  }\n}": types.EditCustomizeItemDocument,
    "mutation DeleteCustomizeItem($id: String!) {\n  delete_customize_store_by_pk(id:$id) {\n    id\n  }\n}": types.DeleteCustomizeItemDocument,
    "query GetMaxCustomizeStoreItemOrder {\n  customize_store_aggregate(where: {order: {_is_null: false}}) {\n    aggregate {\n      max {\n        order\n      }\n    }\n  }\n}": types.GetMaxCustomizeStoreItemOrderDocument,
    "query ListItemDefinitions {\n  item_definition(order_by: {id: asc}) {\n    id\n    description\n  }\n}": types.ListItemDefinitionsDocument,
    "mutation ReorderCustomizeStoreItems($neworder: jsonb!) {\n  reorder_customize_store(args: {neworder: $neworder}) {\n    id\n  }\n}": types.ReorderCustomizeStoreItemsDocument,
    "query ListNonfreeCabinTemplate {\n  cabin_template(order_by: {type: asc_nulls_last}, where: {type: {_neq: \"free\"}}) {\n    template_id\n    type\n    definition {\n      id\n      name: config(path: \"name\")\n    }\n  }\n}": types.ListNonfreeCabinTemplateDocument,
    "query QueryMaintenanceLog($after: bigint = \"9223372036854775807\") {\n  maintenance_log(order_by: {id: desc}, where: {id: {_lt: $after}}, limit: 10) {\n    id\n    user_id\n    data\n    created_at\n  }\n}": types.QueryMaintenanceLogDocument,
    "query GetAllMySector0xCharacters {\n  sector0x {\n    characters {\n      id\n      description\n    }\n  }\n}": types.GetAllMySector0xCharactersDocument,
    "query GetSector0xCharacterById($id: uuid!) {\n  sector0x {\n    characters_by_pk(id: $id) {\n      id\n      description\n    }\n  }\n}": types.GetSector0xCharacterByIdDocument,
    "query GetMyDefaultCharacter($id: String!) {\n  sector0x {\n    user_settings_by_pk(id: $id) {\n      default_character\n    }\n  }\n}": types.GetMyDefaultCharacterDocument,
    "query GetAllMyCharacters {\n  user_character(where: {owned: {_eq: true}}, order_by: {updated_at: desc}) {\n    id\n    reference_id\n    description\n    private_config: private_config_view\n    attributes\n    equipments {\n      slot\n      item_id\n      count\n      data\n    }\n  }\n  sector0x {\n    characters {\n      id\n      description\n    }\n  }\n}": types.GetAllMyCharactersDocument,
    "mutation UpsertUserCharacter($id: uuid!, $description: jsonb!, $reference_id: uuid, $private_config: jsonb) {\n  insert_user_character_one(object: {id: $id, description: $description, reference_id: $reference_id, private_config: $private_config}, on_conflict: {constraint: character_pkey, update_columns: [description, private_config]}) {\n    id\n  }\n}": types.UpsertUserCharacterDocument,
    "query GetSeasonTickets($id: bpchar!) {\n  season_ticket(where: {season_id: {_eq: $id}}, order_by: [{claimed: desc}, {joined_at: asc_nulls_last}, {created_at: asc}, {ticket_id: asc_nulls_last}]) {\n    id: ticket_id\n    description\n    character {\n      id\n      description\n    }\n  }\n}": types.GetSeasonTicketsDocument,
    "query GetPartialSeasonInfo($id: bpchar!) {\n  season_by_pk(id: $id) {\n    world_id\n    tickets(order_by: [{team: asc}, {claimed: desc}, {joined_at: asc_nulls_last}, {created_at: asc}, {ticket_id: asc_nulls_last}]) {\n      id: ticket_id\n      description\n      private_config: private_config_view\n      character {\n        id\n        description\n        owner\n      }\n      team\n    }\n    waitlist(order_by: {created_at: desc}) {\n      character_id\n      description: config\n      private_config: private_config_view\n      info\n    }\n  }\n}": types.GetPartialSeasonInfoDocument,
    "mutation UpdateMyTicket($ticket_id: bpchar!, $season_id: bpchar!, $config: jsonb!, $private_config: jsonb!) {\n  update_season_ticket_by_pk(_set: {config: $config, private_config: $private_config}, pk_columns: {season_id: $season_id, ticket_id: $ticket_id}) {\n    ticket_id\n  }\n}": types.UpdateMyTicketDocument,
    "query GenerateCoupon($id: String = \"\") {\n  generate_coupon(args: {id: $id}) {\n    id\n    expired_at\n    used_at\n  }\n}": types.GenerateCouponDocument,
    "query GetPrivateWorld {\n  private_world_list {\n    id\n    basic_info\n    enabled\n    created_at\n  }\n}": types.GetPrivateWorldDocument,
    "query GetPrivateSeason {\n  private_season_list {\n    id\n    basic_info\n    created_at\n    start_at\n    ticket_count\n    ticket_remain\n    custom {\n      starter\n      config\n      discount\n    }\n  }\n}": types.GetPrivateSeasonDocument,
    'subscription SubscribeWorldCaption($id: bpchar!) {\n  world_by_pk(id: $id) {\n    caption: basic_info(path: "caption")\n  }\n}': types.SubscribeWorldCaptionDocument,
    "query GetPlayerIdList($world: bpchar = \"\") {\n  player(where: {world_id: {_eq: $world}, enabled: {_eq: true}}) {\n    id\n    team\n    character_id\n  }\n}": types.GetPlayerIdListDocument,
    "query GetPlayerInfo($id: bpchar!) {\n  player: player_by_pk(id: $id) {\n    id\n    name: data(path: \"name\")\n    pron: data(path: \"pron\")\n    memories: data(path: \"memories\")\n    textures: data(path: \"textures\")\n    character_id\n  }\n}": types.GetPlayerInfoDocument,
    "query QueryPlayerState($player: bpchar!) {\n  player_by_pk(id: $player) {\n    id\n    action\n    plan\n  }\n}": types.QueryPlayerStateDocument,
    "subscription SubscribePlayerState($player: bpchar!) {\n  player_by_pk(id: $player) {\n    id\n    action\n    plan\n  }\n}": types.SubscribePlayerStateDocument,
    "query QueryLatestConversation($player: bpchar!) {\n  conversation(limit: 100, order_by: {created_at: desc}, where: {participants: {player_id: {_eq: $player}}}) {\n    id\n    created_at\n    participants {\n      player {\n        id\n        name: data(path: \"name\")\n      }\n    }\n    data\n  }\n}": types.QueryLatestConversationDocument,
    'subscription SubscribeLatestConversation($player: bpchar!) {\n  conversation(limit: 100, order_by: {created_at: desc}, where: {participants: {player_id: {_eq: $player}}}) {\n    id\n    created_at\n    participants {\n      player {\n        id\n        name: data(path: "name")\n      }\n    }\n    data\n  }\n}': types.SubscribeLatestConversationDocument,
    "query GetChatHistory($conversation_id: bpchar!) {\n  conversation_log(where: {conversation_id: {_eq: $conversation_id}}, order_by: {created_at: asc}) {\n    id\n    data\n    created_at\n  }\n}": types.GetChatHistoryDocument,
    "subscription GetChatHistoryStream($conversation_id: bpchar!, $id: Int!) {\n  conversation_log_stream(batch_size: 10, cursor: {initial_value: {id: $id}, ordering: ASC}, where: {conversation_id: {_eq: $conversation_id}}) {\n    id\n    data\n    created_at\n  }\n}": types.GetChatHistoryStreamDocument,
    "query GetPlayerIdFromCharacterId($world: bpchar!, $character: uuid!) {\n  player(where: {world_id: {_eq: $world}, character_id: {_eq: $character}}) {\n    id\n  }\n}": types.GetPlayerIdFromCharacterIdDocument,
    'query GetPlayerData($id: bpchar!) {\n  player: player_by_pk(id: $id) {\n    id\n    data\n    character {\n      equipments(where: {slot: {_eq: "bubble"}}) {\n        item {\n          description\n        }\n        data\n      }\n    }\n    team\n  }\n}': types.GetPlayerDataDocument,
    "query GetCurrentCabin {\n  current_cabin {\n    id\n    config: merged_config\n  }\n}": types.GetCurrentCabinDocument,
    "query GetCabin($id: bpchar!) {\n  cabin_by_pk(id: $id) {\n    id\n    merged_config\n    user_id\n    template_id\n  }\n  cabin_channel(args: {id: $id}) {\n    id\n  }\n}": types.GetCabinDocument,
    "subscription SubscribeCabin($id: bpchar!) {\n  cabin_by_pk(id: $id) {\n    id\n    merged_config\n    user_id\n    template_id\n  }\n}": types.SubscribeCabinDocument,
    "query GetCabinCharacter($id: bpchar!) {\n  cabin_character(where: {_and: [{cabin_id: {_eq: $id}}, {character: {owned: {_eq: true}}}]}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n  }\n}": types.GetCabinCharacterDocument,
    "query GetMyCharacterInCabin($owner: String!) {\n  cabin_character(where: {character: {owner: {_eq: $owner}}}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n    cabin {\n      id\n      owned\n    }\n    enabled\n  }\n}": types.GetMyCharacterInCabinDocument,
    "subscription SubscribeMyCharacterInCabin($owner: String!) {\n  cabin_character(where: {character: {owner: {_eq: $owner}}}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n    cabin {\n      id\n      owned\n    }\n    enabled\n  }\n}": types.SubscribeMyCharacterInCabinDocument,
    "subscription SubscribeCabinWaitingRoom($id: bpchar!) {\n  cabin_character(where: {cabin_id: {_eq: $id}}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n    enabled\n    character {\n      id\n      owner\n      owned\n    }\n  }\n}": types.SubscribeCabinWaitingRoomDocument,
    "subscription SubscribeCabinWaitingRoomGuest($id: bpchar!) {\n  cabin_character(where: {cabin_id: {_eq: $id}, character: {owned: {_eq: false}}}, order_by: [{joined_at: desc}, {character_id: asc}]) {\n    cabin_id\n    character_id\n    enabled\n    character {\n      id\n      owner\n    }\n  }\n}": types.SubscribeCabinWaitingRoomGuestDocument,
    "mutation UpdateCabinCharacterList($cabin_id: String!, $character_list: jsonb!) {\n  update_cabin_character_list(args: {cabin_id: $cabin_id, character_list: $character_list}) {\n    cabin_id\n    character_id\n    joined_at\n  }\n}": types.UpdateCabinCharacterListDocument,
    "mutation RecallCabinCharacter($cabin_id: bpchar!, $character_id: uuid!) {\n  delete_cabin_character_by_pk(cabin_id: $cabin_id, character_id: $character_id) {\n    cabin_id\n    character_id\n  }\n}": types.RecallCabinCharacterDocument,
    "mutation CloseCabinWaitingList($cabin_id: bpchar!) {\n  delete_cabin_character(where: {cabin_id: {_eq: $cabin_id}, character: {owned: {_eq: false}}}) {\n    affected_rows\n  }\n  delete_cabin_code_by_pk(cabin_id: $cabin_id) {\n    code\n  }\n}": types.CloseCabinWaitingListDocument,
    "mutation PublicCabinWaitingList($cabin_id: bpchar!) {\n  update_cabin_code_by_pk(pk_columns: {cabin_id: $cabin_id}, _set: {is_public: true}) {\n    code\n  }\n}": types.PublicCabinWaitingListDocument,
    "mutation PrivateCabinWaitingList($cabin_id: bpchar!) {\n  update_cabin_code_by_pk(pk_columns: {cabin_id: $cabin_id}, _set: {is_public: false}) {\n    code\n  }\n}": types.PrivateCabinWaitingListDocument,
    "query GetPublicCabin($cabin_id: bpchar!) {\n  cabin_code_by_pk(cabin_id: $cabin_id) {\n    is_public\n  }\n}": types.GetPublicCabinDocument,
    "query ListPublicCabin($limit: Int = 100) {\n  cabin_code(where: {is_public: {_eq: true}}, limit: $limit, order_by: {created_at: desc}) {\n    cabin {\n      id\n      basic_info\n      owned\n    }\n  }\n}": types.ListPublicCabinDocument,
    "subscription SubscriptPublicCabinHead {\n  cabin_code(where: {is_public: {_eq: true}}, limit: 1) {\n    cabin {\n      id\n      owned\n    }\n  }\n}": types.SubscriptPublicCabinHeadDocument,
    "query GetCharacter($id: uuid!) {\n  user_character_by_pk(id: $id) {\n    id\n    description\n    private_config: private_config_view\n    owner\n    equipments {\n      item {\n        id\n        description\n      }\n      slot\n      data\n      count\n    }\n  }\n}": types.GetCharacterDocument,
    "query QueryLatestCabinLog($id: uuid!) {\n  cabin_log(where: {participants: {character_id: {_eq: $id}}}, order_by: {created_at: desc}, limit: 1) {\n    id\n    data\n    summary\n  }\n}": types.QueryLatestCabinLogDocument,
    "subscription SubscribeLatestCabinLog($id: uuid!) {\n  cabin_log(where: {participants: {character_id: {_eq: $id}}}, order_by: {created_at: desc}, limit: 1) {\n    id\n    data\n    summary\n  }\n}": types.SubscribeLatestCabinLogDocument,
    'query QueryCabinLog($before: timestamptz!, $id: uuid!) {\n  cabin_log(limit: 10, where: {created_at: {_lt: $before}, participants: {character_id: {_eq: $id}}}, order_by: {created_at: desc}) {\n    id\n    data\n    summary\n    created_at\n    participants {\n      character {\n        id\n        name: description(path: "name")\n      }\n    }\n  }\n}': types.QueryCabinLogDocument,
    "subscription SubscribeCabinEnergyState($user_id: String!) {\n  cabin_energy_by_pk(user_id: $user_id) {\n    energy\n    premium_energy\n  }\n}": types.SubscribeCabinEnergyStateDocument,
    "mutation InsertMessage($channel: uuid!, $msg: jsonb!, $user_id: String!, $author_id: uuid!) {\n  insert_messages_one(object: {author_id: $author_id, channel_id: $channel, message: $msg, user_id: $user_id}) {\n    id\n  }\n}": types.InsertMessageDocument,
    "subscription GetMessagesStreamingSubscription($channel: uuid!, $cursor: bigint = 0, $batch_size: Int = 20) {\n  messages_stream(batch_size: $batch_size, cursor: {initial_value: {id: $cursor}}, where: {channel_id: {_eq: $channel}}) {\n    id\n    message\n    user_id\n    created_at\n    author_id\n  }\n}": types.GetMessagesStreamingSubscriptionDocument,
    "query GetOldMessages($channel: uuid!, $offset: Int = 0, $limit: Int = 20) {\n  messages(where: {channel_id: {_eq: $channel}}, limit: $limit, offset: $offset, order_by: {id: desc}) {\n    id\n    message\n    user_id\n    created_at\n    author_id\n  }\n}": types.GetOldMessagesDocument,
    "query SubscribeCabinCode($cabin_id: bpchar!) {\n  cabin_code_by_pk(cabin_id: $cabin_id) {\n    code\n    expired_at\n    is_public\n  }\n}": types.SubscribeCabinCodeDocument,
    "mutation GenerateCabinCode($cabin_id: String!) {\n  generate_cabin_code(args: {cabin_id: $cabin_id}) {\n    code\n  }\n}": types.GenerateCabinCodeDocument,
    "query ResolveCabinCode($code: String!) {\n  resolve_cabin_code(args: {code: $code}) {\n    id\n    owned\n  }\n}": types.ResolveCabinCodeDocument,
    "mutation UpdateCabinInfo($cabin_id: String!, $value: jsonb!) {\n  update_cabin_info(args: {cabin_id: $cabin_id, value: $value}) {\n    id\n    merged_config\n  }\n}": types.UpdateCabinInfoDocument,
    "mutation UpdateCabinWaitingList($cabin_id: String!, $character_list: jsonb!) {\n  update_cabin_waiting_list(args: {cabin_id: $cabin_id, character_list: $character_list}) {\n    cabin_id\n    character_id\n  }\n}": types.UpdateCabinWaitingListDocument,
    "query GetPremiumStatus($user_id: String!) {\n  user_premium_by_pk(user_id: $user_id) {\n    begin_at\n    duration\n    end_at\n    expired\n    type\n  }\n}": types.GetPremiumStatusDocument,
    "mutation RefillCabinPremiumEnergy {\n  refill_cabin_premium_energy {\n    premium_energy\n  }\n}": types.RefillCabinPremiumEnergyDocument,
    "query QueryCabinTemplateStore {\n  cabin_template(order_by: [{owned: asc}, {created_at: desc}], where: {enabled: {_eq: true}, type: {_eq: \"store\"}}) {\n    template_id\n    info\n    data\n    created_at\n    owned\n  }\n}": types.QueryCabinTemplateStoreDocument,
    'query QueryCabinTemplateList {\n  cabin_template(order_by: {created_at: desc}, where: {enabled: {_eq: true}, type: {_neq: "store"}}) {\n    template_id\n    type\n    info\n    created_at\n  }\n  cabin_template_owned(order_by: {created_at: desc}) {\n    template_id\n    info\n    created_at\n    description\n  }\n}': types.QueryCabinTemplateListDocument,
    "mutation CabinChooseTheme($cabin_id: bpchar!, $template_id: bpchar!) {\n  cabin_choose_theme(args: {cabin_id: $cabin_id, template_id: $template_id}) {\n    updated_at\n  }\n}": types.CabinChooseThemeDocument,
    "query GetLimitedThemes {\n  cabin_template(where: {enabled: {_eq: true}, type: {_eq: \"limited\"}}) {\n    template_id\n    info\n  }\n}": types.GetLimitedThemesDocument,
    "query GetExportedWorldTemplate {\n  exported_world_template {\n    id\n    config\n  }\n}": types.GetExportedWorldTemplateDocument,
    "mutation UpdateSeasonConfig($season_id: String!, $new_config: jsonb!) {\n  update_season_config(args: {season_id: $season_id, new_config: $new_config}) {\n    id\n  }\n}": types.UpdateSeasonConfigDocument,
    "mutation UpdateWorldConfigUser($season_id: String!, $new_config: jsonb!) {\n  update_world_config(args: {season_id: $season_id, new_config: $new_config}) {\n    id\n  }\n}": types.UpdateWorldConfigUserDocument,
    "query GetMySeason {\n  season(where: {active: {_eq: true}, custom: {owned: {_eq: true}}}, order_by: {start_at: asc}) {\n    id\n    start_at\n    end_at\n    ticket_count\n    ticket_remain\n    basic_info\n    visible\n    custom {\n      starter\n      config\n      discount\n    }\n    recruit {\n      end_at\n      min_player_count\n    }\n  }\n}": types.GetMySeasonDocument,
    "subscription SuibscribeSeasonCustom($id: bpchar!) {\n  season_custom_by_pk(id: $id) {\n    owned\n    config\n  }\n}": types.SuibscribeSeasonCustomDocument,
    "query QueryUserToken($user_id: String!) {\n  user_token_by_pk(user_id: $user_id) {\n    tokens\n  }\n}": types.QueryUserTokenDocument,
    "query QueryUserBalance {\n  sector0x {\n    my_balance {\n      balance\n    }\n  }\n}": types.QueryUserBalanceDocument,
    "mutation AcquireCabinTemplateFromStore($template_id: String!) {\n  acquire_cabin_template_from_store(args: {template_id: $template_id}) {\n    info\n  }\n}": types.AcquireCabinTemplateFromStoreDocument,
    "query QueryUnclaimedSeasonBonus($user: String!) {\n  season_bonus(where: {owner: {_eq: $user}, claimed_at: {_is_null: true}}) {\n    value\n    season_id\n    world_id\n  }\n}": types.QueryUnclaimedSeasonBonusDocument,
    "query QuerySeasonBonus($id: bpchar!) {\n  season_bonus(where: { world_id: {_eq: $id} }) {\n    season_id\n    value\n    claimed_at\n  }\n}": types.QuerySeasonBonusDocument,
    "mutation ClaimSeasonBonus($season_id: String!) {\n  claim_season_bonus(args: {season_id: $season_id}) {\n    season_id\n    value\n    claimed_at\n  }\n}": types.ClaimSeasonBonusDocument,
    "query GetMissionList {\n  mission_sort(order_by: {sort_order: asc}) {\n    mission {\n      id\n      description\n    }\n    type\n  }\n}": types.GetMissionListDocument,
    "query GetMissionInstanceInfo($id: bpchar!) {\n  mission_instance_by_pk(id: $id) {\n    data\n    definition {\n      id\n      description\n    }\n    canceled_at\n    result {\n      data\n      claimed_at\n      created_at\n      finished_at\n    }\n  }\n}": types.GetMissionInstanceInfoDocument,
    "query GetActiveMissions {\n  mission_instance(where: {_or: [{_not: {result: {id: {_is_null: false}}}}, {result: {finished: {_eq: false}}}, {result: {claimed_at: {_is_null: true}}}], canceled_at: {_is_null: true}}) {\n    id\n    data\n    definition {\n      id\n      description\n      sort {\n        type\n      }\n    }\n    result {\n      finished_at\n    }\n  }\n}": types.GetActiveMissionsDocument,
    "subscription SubscribeActiveMissions {\n  mission_instance(where: {_or:[{_not:{result:{id:{_is_null:false}}}},{result:{finished:{_eq:false}}},{result:{claimed_at:{_is_null:true}}}]}) {\n    id\n    data\n    definition {\n      id\n      description\n      sort {\n        type\n      }\n    }\n    result {\n      finished_at\n    }\n  }\n}": types.SubscribeActiveMissionsDocument,
    "query GetMyMissionHistory($where: mission_instance_bool_exp!) {\n  mission_instance(where: $where, order_by: {created_at: desc}, limit: 20) {\n    id\n    data\n    created_at\n    definition {\n      id\n      description\n      sort {\n        type\n      }\n    }\n  }\n}": types.GetMyMissionHistoryDocument,
    "query GetItemDefinition($id: String!) {\n  item_definition_by_pk(id: $id) {\n    description\n  }\n}": types.GetItemDefinitionDocument,
    "mutation ClaimMissionResultRewards($id: bpchar!) {\n  claim_mission_result_rewards(args: {instance_id: $id}) {\n    claimed_at\n  }\n}": types.ClaimMissionResultRewardsDocument,
    "query getMyItems {\n  user_item {\n    item_id\n    count\n  }\n}": types.GetMyItemsDocument,
    "query getCharacterInMission {\n  user_character_in_mission {\n    id\n  }\n}": types.GetCharacterInMissionDocument,
    "query GetSupplyList {\n  supply {\n    id\n    price\n    refill_cycle\n    data\n    count\n    count_key\n  }\n}": types.GetSupplyListDocument,
    "query QuerySupplyLogCount($id: String!, $count_key: String!) {\n  supply_log_aggregate(where: {supply_id: {_eq: $id}, count_key: {_eq: $count_key}}) {\n    aggregate {\n      count\n    }\n  }\n}": types.QuerySupplyLogCountDocument,
    "mutation UseSupply($supply_id: String!) {\n  use_supply(args: {supply_id: $supply_id}) {\n    transaction_id\n  }\n}": types.UseSupplyDocument,
    "query CurrentMissionInstanceCount {\n  current_mission_instance_count {\n    count\n  }\n}": types.CurrentMissionInstanceCountDocument,
    "query GetConfigByKey($key: String!) {\n  config_by_pk(key: $key) {\n    value\n  }\n}": types.GetConfigByKeyDocument,
    "mutation CancelMission($id: bpchar!) {\n  cancel_mission_instance(args: {id: $id}) {\n    id\n  }\n}": types.CancelMissionDocument,
    "query ListCustomizeStore {\n  customize_store(order_by: {order: asc}, where: {order: {_is_null: false}}) {\n    id\n    data\n  }\n}": types.ListCustomizeStoreDocument,
    "query ListMyItemForType($type: String!) {\n  user_item(where: {item: {type: {_eq: $type}}}, order_by: {item_id: asc}) {\n    item_id\n    count\n    item {\n      description\n    }\n  }\n}": types.ListMyItemForTypeDocument,
    "mutation ResetCharacterEquipment($id: uuid!, $slot: String!) {\n  delete_user_character_equipment_by_pk(id: $id, slot: $slot) {\n    slot\n  }\n}": types.ResetCharacterEquipmentDocument,
    "mutation SetCharacterEquipment($id: uuid!, $slot: String!, $item_id: String!, $data: jsonb!, $count: Int = 1) {\n  insert_user_character_equipment_one(object: {id: $id, slot: $slot, item_id: $item_id, data: $data, count: $count}, on_conflict: {constraint: user_character_equipment_pkey, update_columns: [item_id, data]}) {\n    slot\n  }\n}": types.SetCharacterEquipmentDocument,
    "query GetCharacterRenameHistory($id: uuid!) {\n  user_character_rename_history(where: {id: {_eq: $id}, old_name: {_neq: \"\"}}) {\n    created_at\n  }\n}": types.GetCharacterRenameHistoryDocument,
    "mutation ClaimFreeEnergy {\n  promotion_claim_free_energy {\n    created_at\n  }\n}": types.ClaimFreeEnergyDocument,
    "mutation PinMessage($character_id: uuid!, $data: jsonb!) {\n  pin_message(args: {character_id: $character_id, data: $data}) {\n    value\n  }\n}": types.PinMessageDocument,
    "query ListCharacterPinnedMessages($id: uuid!) {\n  user_character_pinned_message(order_by: {created_at: desc}, where: {character_id: {_eq: $id}}) {\n    id\n    data\n    created_at\n  }\n  config(where:{key:{_eq:\"default.max_pin_messages\"}}) {\n    value\n  }\n}": types.ListCharacterPinnedMessagesDocument,
    "mutation DeletePinnedMessage($id: bigint!) {\n  delete_user_character_pinned_message_by_pk(id: $id) {\n    id\n  }\n}": types.DeletePinnedMessageDocument,
    "query CheckMessagePinned($character_id: uuid!, $content_digest: uuid!) {\n  user_character_pinned_message(where: {character_id: {_eq: $character_id}, content_digest: {_eq: $content_digest}}) {\n    id\n  }\n}": types.CheckMessagePinnedDocument,
    "query GetWorldPlayerState($world: bpchar!) {\n  player(where: {world_id: {_eq: $world}}) {\n    id\n    x\n    y\n    face\n    action\n    plan\n  }\n}": types.GetWorldPlayerStateDocument,
    "query GetCharacterOwner($id: uuid!) {\n  user_character_by_pk(id: $id) {\n    owner\n  }\n}": types.GetCharacterOwnerDocument,
    "mutation UpdateMyWaitlistEntry($season_id: bpchar!, $character_id: uuid!, $config: jsonb!, $private_config: jsonb) {\n  update_season_waitlist_by_pk(pk_columns: {season_id: $season_id, character_id: $character_id}, _set: {config: $config, private_config: $private_config}) {\n    created_at\n  }\n}": types.UpdateMyWaitlistEntryDocument,
    "query AlternativeCharacterModel {\n  alternative_character_model(order_by: {order: asc_nulls_first}) {\n    id\n    description\n  }\n}": types.AlternativeCharacterModelDocument,
    "query CharacterSpriteHistory($id: uuid!, $limit: Int!) {\n  user_character_sprite_history(where: {id: {_eq: $id}}, order_by: {created_at: desc}, limit: $limit) {\n    url\n    config\n  }\n}": types.CharacterSpriteHistoryDocument,
    "query GetConfig {\n  config {\n    key\n    value\n  }\n}": types.GetConfigDocument,
    "mutation DirectorUpdateWorld($updates: [world_updates!]!, $changes: [world_director_log_insert_input!]!) {\n  update_world_many(updates: $updates) {\n    affected_rows\n  }\n  insert_world_director_log(objects: $changes) {\n    affected_rows\n  }\n}": types.DirectorUpdateWorldDocument,
    "query GetWorldById($id: bpchar!) {\n  world_by_pk(id: $id) {\n    id\n    config: merged_config\n    created_at\n    expired_after\n    season {\n      id\n    }\n  }\n}": types.GetWorldByIdDocument,
    "mutation DisableWorld($id: bpchar!) {\n  update_world_by_pk(pk_columns: {id: $id}, _set: {enabled: false, expired_after: null}) {\n    enabled\n  }\n}": types.DisableWorldDocument,
    "mutation InsertConversation($world_id: bpchar!, $id: bpchar!, $participants: [conversation_participant_insert_input!]!, $first_log_data: jsonb!) {\n  insert_conversation(objects: {world_id: $world_id, id: $id, participants: {data: $participants}, logs: {data: {data: $first_log_data}}}) {\n    affected_rows\n  }\n}": types.InsertConversationDocument,
    "mutation UpdateConversationDialogues($id: bpchar!, $dialogueData: jsonb!) {\n  update_conversation_by_pk(pk_columns: {id: $id}, _append: {data: $dialogueData}) {\n    id\n  }\n}": types.UpdateConversationDialoguesDocument,
    "mutation InsertConversationLog($conversation_id: bpchar!, $data: jsonb!) {\n  insert_conversation_log_one(object: {conversation_id: $conversation_id, data: $data}) {\n    id\n  }\n}": types.InsertConversationLogDocument,
    "mutation UpdateConfig($key: String!, $value: String!) {\n  insert_config_one(object: {key: $key, value: $value}, on_conflict: {constraint: config_pkey, update_columns: value}) {\n    key\n  }\n}": types.UpdateConfigDocument,
    "mutation DeleteConfig($key: String!) {\n  delete_config_by_pk(key: $key) {\n    key\n  }\n}": types.DeleteConfigDocument,
    "query GetWorldPlayerList($world: bpchar!) {\n  player(where: {world_id: {_eq: $world}, enabled: {_eq: true}}) {\n    id\n    data\n    team\n  }\n}": types.GetWorldPlayerListDocument,
    "query GetPlayerState($id: bpchar!) {\n  player: player_by_pk(id: $id) {\n    x\n    y\n    face\n    action\n    plan\n  }\n}": types.GetPlayerStateDocument,
    "query GetPlayerStates($world: bpchar!) {\n  player(where: {world_id: {_eq: $world}, enabled: {_eq: true}}) {\n    id\n    x\n    y\n    face\n    action\n    plan\n  }\n}": types.GetPlayerStatesDocument,
    "mutation UpdatePlayerState($updates: [player_updates!]!) {\n  update_player_many(updates: $updates) {\n    affected_rows\n  }\n}": types.UpdatePlayerStateDocument,
    "mutation UpdatePlayers($updates: [player_updates!]!) {\n  update_player_many(updates: $updates) {\n    affected_rows\n  }\n}": types.UpdatePlayersDocument,
    "mutation InsertPlayer($id: bpchar!, $data: jsonb!, $world_id: bpchar!, $x: Int!, $y: Int!, $team: Int!) {\n  player: insert_player_one(object: {id: $id, data: $data, world_id: $world_id, x: $x, y: $y, team: $team}) {\n    id\n  }\n}": types.InsertPlayerDocument,
    "mutation RemovePlayer($id: bpchar!) {\n  delete_player_by_pk(id: $id) {\n    id\n  }\n}": types.RemovePlayerDocument,
    "mutation UpdatePlayerData($id: bpchar!, $data: jsonb!, $team: Int!) {\n  update_player_by_pk(pk_columns: {id: $id}, _set: {data: $data, team: $team}) {\n    id\n    enabled\n  }\n}": types.UpdatePlayerDataDocument,
    "mutation UpdatePlayerEnabled($id: bpchar!, $enabled: Boolean!) {\n  update_player_by_pk(pk_columns: {id: $id}, _set: {enabled: $enabled}) {\n    id\n    data\n    team\n  }\n}": types.UpdatePlayerEnabledDocument,
    "query AccessMemory($target_player: bpchar!, $types: _text!, $excluded: _int4!, $query: vector!, $max_count: Int!, $min_score: float8!) {\n  access_memory(args: {target_player: $target_player, types: $types, excluded: $excluded, query: $query, max_count: $max_count, min_score: $min_score}) {\n    id\n    data\n    content\n  }\n}": types.AccessMemoryDocument,
    "query GetLatestMemory($player: bpchar!) {\n  memory(where: {player_id: {_eq: $player}}, order_by: {created_at: desc}, limit: 10) {\n    id\n    data\n    content\n    created_at\n  }\n}": types.GetLatestMemoryDocument,
    "mutation InsertMemories($objects: [memory_insert_input!]!) {\n  insert_memory(objects: $objects) {\n    returning {\n      id\n    }\n  }\n}": types.InsertMemoriesDocument,
    "mutation InsertPlayerIntrospectionLog($player_id: bpchar = \"\", $content: String = \"\") {\n  insert_player_introspection_log_one(object: {player_id: $player_id, content: $content}) {\n    id\n  }\n}": types.InsertPlayerIntrospectionLogDocument,
    "query GetConversationDialogues($id: bpchar!) {\n  conversation_by_pk(id: $id) {\n    data\n    logs(order_by: {created_at: asc}) {\n      data\n    }\n  }\n}": types.GetConversationDialoguesDocument,
    "query GetConversationLog($id: bpchar!) {\n  conversation_log(where: {conversation_id: {_eq: $id}}, order_by: {created_at: asc}) {\n    data\n    created_at\n  }\n}": types.GetConversationLogDocument,
    'mutation InsertLLMLog($model: String!, $input: String!, $description: String = "") {\n  insert_llm_log_one(object: {model: $model, input: $input, description: $description}) {\n    id\n  }\n}': types.InsertLlmLogDocument,
    "mutation UpdateLLMLog($id: Int!, $cabin: String, $world: String, $player: String, $type: String!, $source: String!, $tokens: Int = 0, $output: String!) {\n  insert_llm_token_log_one(object: {llm_log_id: $id, cabin: $cabin, world: $world, player: $player, type: $type, source: $source, tokens: $tokens}) {\n    id\n  }\n  update_llm_log_by_pk(pk_columns: {id: $id}, _set: {output: $output}) {\n    id\n  }\n}": types.UpdateLlmLogDocument,
    "query QueryActiveSeasons {\n  season(order_by: {start_at: asc}, where: {world_id: {_is_null: true}, open: {_eq: true}}) {\n    id\n    config\n    merged_config\n    template_id\n    visible_at\n    start_at\n    end_at\n    tickets(order_by: [{team: asc}, {claimed: desc}, {joined_at: asc_nulls_last}, {created_at: asc}, {ticket_id: asc_nulls_last}]) {\n      id: ticket_id\n      description\n      character {\n        id\n        description\n        owner\n      }\n      refund_info\n      team\n    }\n    waitlist {\n      character {\n        id\n        owner\n      }\n      config\n      refund_info\n    }\n    recruit {\n      end_at\n      min_player_count\n    }\n    custom {\n      owner\n      starter\n      config\n      discount\n    }\n  }\n}": types.QueryActiveSeasonsDocument,
    "query GetSeasonInfo($id: bpchar!) {\n  season_by_pk(id: $id) {\n    id\n    start_at\n    end_at\n    created_at\n    visible\n    merged_config\n    world_id\n    open\n    tickets(order_by: [{team: asc}, {claimed: desc}, {joined_at: asc_nulls_last}, {created_at: asc}, {ticket_id: asc_nulls_last}]) {\n      id: ticket_id\n      description\n      character {\n        id\n        description\n        owner\n      }\n      team\n    }\n    waitlist(order_by: {created_at: desc}) {\n      character {\n        id\n        owner\n      }\n      config\n      info\n    }\n    recruit {\n      end_at\n      min_player_count\n    }\n    custom {\n      starter\n      config\n      discount\n      owner\n    }\n    abandoned {\n      reason\n      created_at\n    }\n  }\n}": types.GetSeasonInfoDocument,
    "mutation AcquireSeasonTicket($season_id: bpchar!, $character_id: uuid!, $user_id: String!, $coupon_id: String, $team: Int!) {\n  acquire_season_ticket_for_team(args: {season_id: $season_id, character_id: $character_id, user_id: $user_id, coupon_id: $coupon_id, team: $team}) {\n    ticket_id\n  }\n}": types.AcquireSeasonTicketDocument,
    "mutation PreCheckSeasonTicket($season_id: bpchar!, $character_id: uuid!, $user_id: String!, $team: Int!) {\n  precheck_season_ticket_for_team(args: {season_id: $season_id, character_id: $character_id, user_id: $user_id, team: $team}) {\n    ticket_id\n  }\n}": types.PreCheckSeasonTicketDocument,
    "mutation InsertUserBalanceChangesOne($user_id: String!, $description: jsonb!, $nonce: String, $value: numeric!) {\n  sector0x {\n    insert_user_balance_changes_one(object: {user_id: $user_id, description: $description, nonce: $nonce, value: $value}) {\n      id\n    }\n  }\n}": types.InsertUserBalanceChangesOneDocument,
    "mutation RefundUserBalanceChange($id: uuid!, $user_id: String!, $description: jsonb!, $value: numeric!) {\n  sector0x {\n    update_user_balance_changes_by_pk(pk_columns: {id: $id}, _set: {nonce: null}) {\n      id\n    }\n    insert_user_balance_changes_one(object:{user_id: $user_id, description: $description, value: $value}) {\n      id\n    }\n  }\n}": types.RefundUserBalanceChangeDocument,
    "mutation CreateWorldForSeason($id: bpchar!, $world_id: bpchar!, $expired_after: timestamptz!, $config: jsonb!, $template_id: bpchar, $players: [player_insert_input!]!) {\n  insert_world_one(object: {id: $world_id, expired_after: $expired_after, config: $config, template_id: $template_id, players: {data: $players}}) {\n    created_at\n  }\n  update_season_by_pk(pk_columns: {id: $id}, _set: {world_id: $world_id}) {\n    world_id\n  }\n  insert_channels_one(object: {world: $world_id}) {\n    id\n  }\n  generate_default_season_bonuses(args: {season_id: $id}) {\n    owner\n  }\n}": types.CreateWorldForSeasonDocument,
    "query GetCouponInfo($id: bpchar!) {\n  coupon_by_pk(id: $id) {\n    user_id\n    used_at\n    expired_at\n    description\n  }\n}": types.GetCouponInfoDocument,
    "query getCabinOwner($id: bpchar!) {\n  cabin_by_pk(id: $id) {\n    owner: user_id\n  }\n}": types.GetCabinOwnerDocument,
    "query getCabinInfo($id: bpchar!) {\n  cabin_by_pk(id: $id) {\n    user_id\n    merged_config\n    characters(where: {enabled: {_eq: true}}) {\n      character {\n        id\n        description\n      }\n    }\n  }\n}": types.GetCabinInfoDocument,
    "mutation ConsumeCabinEnergy($user_id: String!) {\n  consume_cabin_energy(args: {target: $user_id, delta: \"1 seconds\"}) {\n    energy\n  }\n}": types.ConsumeCabinEnergyDocument,
    "mutation RefillCabinEnergy($target: String!, $delta: interval!) {\n  refill_cabin_energy(args: {target: $target, delta: $delta}) {\n    energy\n  }\n}": types.RefillCabinEnergyDocument,
    "mutation InsertCabinLog($cabin_id: bpchar!, $created_at: timestamptz!, $data: jsonb!, $summary: String, $participants: [cabin_log_participant_insert_input!]!) {\n  insert_cabin_log_one(object: {cabin_id: $cabin_id, created_at: $created_at, data: $data, summary: $summary, participants: {data: $participants}}) {\n    cabin_id\n  }\n}": types.InsertCabinLogDocument,
    "query CheckFeatureEvaluation($feature_id: String!, $user_id: String!) {\n  sector0x {\n    feature_evaluation: feature_evaluations_by_pk(feature_id: $feature_id, user_id: $user_id) {\n      expired_at\n    }\n  }\n}": types.CheckFeatureEvaluationDocument,
    "mutation ActivateUserPremium($id: bpchar!, $user_id: String!, $data: jsonb!, $duration: interval!, $type: String!) {\n  insert_user_premium_log_one(object: {id: $id, user_id: $user_id, data: $data, duration: $duration}) {\n    id\n    premium {\n      end_at\n    }\n  }\n  premium_giveaway_template(args: {user_id: $user_id, premium_type: $type}) {\n    template_id\n    info\n  }\n}": types.ActivateUserPremiumDocument,
    "mutation CreatePushMessage($users: [String!]!, $message: PushMessage!) {\n  sector0x {\n    create_push_message(users: $users, message: $message) {\n      sendno\n      msg_id\n    }\n  }\n}": types.CreatePushMessageDocument,
    "mutation CreateSeason($id: bpchar!, $config: jsonb!, $template_id: bpchar!, $start_at: timestamptz!, $end_at: timestamptz!, $ticket_count: Int!, $starter: uuid!, $custom: jsonb!, $discount: numeric = 0, $recruit: season_recruit_arr_rel_insert_input) {\n  insert_season_one(object: {id: $id, config: $config, template_id: $template_id, start_at: $start_at, end_at: $end_at, custom_for_insert: {data: {starter: $starter, config: $custom, discount: $discount}}, recruit_for_insert: $recruit}) {\n    created_at\n  }\n  insert_season_ticket_one(object: {season_id: $id, character_id: $starter, end_at: $start_at}) {\n    ticket_id\n  }\n  allocate_season_tickets(args: {end_at: $start_at, season_id: $id, ticket_count: $ticket_count}) {\n    ticket_id\n  }\n}": types.CreateSeasonDocument,
    "query GetWorldTemplate($id: bpchar!) {\n  world_template_by_pk(id: $id) {\n    config\n  }\n}": types.GetWorldTemplateDocument,
    "mutation FinishSeasonRecruit($id: bpchar!) {\n  delete_season_recruit_by_pk(id: $id) {\n    id\n  }\n}": types.FinishSeasonRecruitDocument,
    "mutation AbandonSeason($id: bpchar!, $reason: jsonb!, $balance_refunds: [user_balance_changes_insert_input!]!, $items_refunds: [user_item_changes_insert_input!]!) {\n  sector0x {\n    insert_user_balance_changes(objects: $balance_refunds, on_conflict: {constraint: user_balance_changes_nonce_key, update_columns: []}) {\n      affected_rows\n    }\n  }\n  insert_user_item_changes(objects: $items_refunds) {\n    affected_rows\n  }\n  delete_season_waitlist(where: {season_id: {_eq: $id}}) {\n    affected_rows\n  }\n  insert_season_abandoned_one(object: {id: $id, reason: $reason}) {\n    id\n  }\n}": types.AbandonSeasonDocument,
    "query ListCharacterAttribute($id: uuid!) {\n  user_character_by_pk(id: $id) {\n    description\n    attributes\n  }\n}": types.ListCharacterAttributeDocument,
    "mutation UpdateCharacterAttribute($id: uuid!, $value: jsonb!) {\n  update_character_attribute(args: {id: $id, value: $value}) {\n    value\n  }\n}": types.UpdateCharacterAttributeDocument,
    "mutation UpdateCharacterAttributeWithConsumeItems($id: uuid!, $value: jsonb!, $user_id: String!, $consume_item: bpchar!, $cousume_count: Int!, $data: jsonb!) {\n  insert_user_item_changes_one(object: {user_id: $user_id, item_id: $consume_item, value: $cousume_count, data: $data}) {\n    __typename\n  }\n  update_character_attribute(args: {id: $id, value: $value}) {\n    value\n  }\n}": types.UpdateCharacterAttributeWithConsumeItemsDocument,
    "query GetMissions {\n  mission {\n    id\n    description\n    data\n  }\n}": types.GetMissionsDocument,
    "query GetMissionData($id: bpchar!) {\n  mission_by_pk(id: $id) {\n    data\n  }\n}": types.GetMissionDataDocument,
    "query GetMissionInstance($id: bpchar!) {\n  mission_instance_by_pk(id: $id) {\n    mission\n    user_id\n    data\n  }\n}": types.GetMissionInstanceDocument,
    "mutation InsertMissionInstance($mission: bpchar!, $user_id: String!, $data: jsonb!) {\n  try_increase_mission_instance_count(args: {target_user: $user_id}) {\n    count\n  }\n  insert_mission_instance_one(object: {mission: $mission, user_id: $user_id, data: $data}) {\n    id\n  }\n}": types.InsertMissionInstanceDocument,
    "mutation InsertMissionResult($id: bpchar!, $data: jsonb!, $finished_at: timestamptz!) {\n  insert_mission_result_one(object: {id: $id, data: $data, finished_at: $finished_at}) {\n    created_at\n  }\n}": types.InsertMissionResultDocument,
    "mutation CancelMissionInstance($canceled_at: timestamptz!, $id: bpchar!) {\n  update_mission_instance(_set: {canceled_at: $canceled_at}, where: {id: {_eq: $id}}) {\n    affected_rows\n  }\n}": types.CancelMissionInstanceDocument,
    "query GetPendingMissionInstance {\n  mission_instance(where: {_not: {result: {id: {_is_null: false}}}, canceled_at: {_is_null: true}}) {\n    id\n    data\n    definition {\n      data\n    }\n  }\n}": types.GetPendingMissionInstanceDocument,
    "query GetEnabledWorldInfo {\n  world(where: {enabled: {_eq: true}}) {\n    id\n    merged_config\n    expired\n    expired_after\n    director_state\n    created_at\n    character_list\n    season {\n      id\n    }\n  }\n}": types.GetEnabledWorldInfoDocument,
    "mutation trainCharacter($user_id: String!, $data: jsonb!, $character_id: uuid!, $experience: Int!) {\n  insert_user_item_changes_one(object: {user_id: $user_id, value: -1, item_id: \"note\", data: $data}) {\n    id\n  }\n  train_character(args: {character_id: $character_id, experience: $experience}) {\n    value\n  }\n}": types.TrainCharacterDocument,
    "mutation wasteItem($user_id: String!, $data: jsonb!, $item_id: bpchar!, $count: Int = -1) {\n  insert_user_item_changes_one(object: {user_id: $user_id, value: $count, item_id: $item_id, data: $data}) {\n    id\n  }\n}": types.WasteItemDocument,
    "mutation ExchangeTokens($user_id: String!, $description: jsonb!, $data: jsonb!) {\n  sector0x {\n    insert_user_balance_changes_one(object: {user_id: $user_id, description: $description, value: -50}) {\n      id\n    }\n  }\n  insert_user_token_changes_one(object: {user_id: $user_id, value: 1000, data: $data}) {\n    id\n  }\n}": types.ExchangeTokensDocument,
    "query GetCustomizeStoreItem($id: String!) {\n  customize_store_by_pk(id: $id) {\n    data\n    order\n  }\n}": types.GetCustomizeStoreItemDocument,
    "mutation PurchaseCustomizeStoreItemByBalance($user_id: String!, $value: numeric!, $description: jsonb!, $changes: [user_item_changes_insert_input!]!) {\n  sector0x {\n    insert_user_balance_changes_one(object: {user_id: $user_id, value: $value, description: $description}) {\n      id\n    }\n  }\n  insert_user_item_changes(objects: $changes) {\n    affected_rows\n  }\n}": types.PurchaseCustomizeStoreItemByBalanceDocument,
    "mutation PurchaseCustomizeStoreItemByToken($user_id: String!, $value: Int!, $data: jsonb!, $changes: [user_item_changes_insert_input!]!) {\n  insert_user_token_changes_one(object: {user_id: $user_id, value: $value, data: $data}) {\n    id\n  }\n  insert_user_item_changes(objects: $changes) {\n    affected_rows\n  }\n}": types.PurchaseCustomizeStoreItemByTokenDocument,
    'query GetUserCharacterOwnerAndRenameHistory($id: uuid!) {\n  user_character_by_pk(id: $id) {\n    owner\n    old_name: description(path: "name")\n    rename_history(where: {old_name: {_neq: ""}}) {\n      created_at\n    }\n  }\n}': types.GetUserCharacterOwnerAndRenameHistoryDocument,
    "mutation ModifyUserCharacter($id: uuid!, $data: jsonb!, $namehash: String!) {\n  update_user_character_by_pk(pk_columns: {id: $id}, _append: {description: $data}, _set: {namehash: $namehash}) {\n    id\n  }\n}": types.ModifyUserCharacterDocument,
    "mutation ModifyUserCharacterWithBalance($id: uuid!, $data: jsonb!, $user_id: String!, $value: numeric!, $description: jsonb!, $namehash: String!) {\n  sector0x {\n    insert_user_balance_changes_one(object: {user_id: $user_id, value: $value, description: $description}) {\n      id\n    }\n  }\n  update_user_character_by_pk(pk_columns: {id: $id}, _append: {description: $data}, _set: {namehash: $namehash}) {\n    id\n  }\n}": types.ModifyUserCharacterWithBalanceDocument,
    "query GetChannelByWorld($world: bpchar!) {\n  channels(where: {world: {_eq: $world}}) {\n    id\n    world\n  }\n}": types.GetChannelByWorldDocument,
    "query GetLastMessageId {\n  messages(order_by: {id: desc}, limit: 1) {\n    id\n  }\n}": types.GetLastMessageIdDocument,
    "subscription SubscribeMessages($batch_size: Int = 10, $id: bigint!) {\n  messages_stream(batch_size: $batch_size, cursor: {initial_value: {id: $id}, ordering: ASC}) {\n    id\n    channel_id\n    author_id\n    user_id\n    message\n    created_at\n    updated_at\n  }\n}": types.SubscribeMessagesDocument,
    "query FetchAllMessages($channel_id: uuid!) {\n  messages(where: {channel_id: {_eq: $channel_id}}) {\n    id\n    channel_id\n    author_id\n    user_id\n    message\n    created_at\n    updated_at\n  }\n}": types.FetchAllMessagesDocument,
    "mutation JoinSeasonWaitlist($season_id: bpchar!, $character_id: uuid!, $user_id: String!, $info: jsonb!, $refund_info: jsonb!, $coupon_id: String) {\n  join_season_waitlist(args: {season_id: $season_id, character_id: $character_id, user_id: $user_id, info: $info, refund_info: $refund_info, coupon_id: $coupon_id}) {\n    created_at\n    character {\n      owner\n    }\n    config\n  }\n}": types.JoinSeasonWaitlistDocument,
    "mutation MyMutation($season_id: bpchar!, $character_id: uuid!) {\n  delete_season_waitlist_by_pk(character_id: $character_id, season_id: $season_id) {\n    created_at\n  }\n}": types.MyMutationDocument,
    "query QueryWaitlistRefundInfo($season_id: bpchar!, $character_id: uuid!) {\n  season_waitlist_by_pk(season_id: $season_id, character_id: $character_id) {\n    refund_info\n    character {\n      owner\n    }\n    config\n  }\n}": types.QueryWaitlistRefundInfoDocument,
    "mutation CancelSeasonWaitlist($balance_refunds: [user_balance_changes_insert_input!]!, $items_refunds: [user_item_changes_insert_input!]!, $character_id: uuid!, $season_id: bpchar!, $balance_nonce: String!) {\n  sector0x {\n    update_user_balance_changes(_set: {nonce: null}, where: {nonce: {_eq: $balance_nonce}}) {\n      affected_rows\n    }\n    insert_user_balance_changes(objects: $balance_refunds) {\n      affected_rows\n    }\n  }\n  insert_user_item_changes(objects: $items_refunds) {\n    affected_rows\n  }\n  delete_season_waitlist_by_pk(season_id: $season_id, character_id: $character_id) {\n    created_at\n  }\n}": types.CancelSeasonWaitlistDocument,
    "mutation ApproveSeasonWaitlist($season_id: bpchar!, $character_id: uuid!) {\n  approve_season_waitlist(args: {season_id: $season_id, character_id: $character_id}) {\n    ticket_id\n    config\n    character {\n      owner\n    }\n  }\n}": types.ApproveSeasonWaitlistDocument,
    "mutation CancelSeasonAllWaitlist($id: bpchar!, $balance_refunds: [user_balance_changes_insert_input!]!, $items_refunds: [user_item_changes_insert_input!]!) {\n  sector0x {\n    insert_user_balance_changes(objects: $balance_refunds, on_conflict: {constraint: user_balance_changes_nonce_key, update_columns: []}) {\n      affected_rows\n    }\n  }\n  delete_season_waitlist(where: {season_id: {_eq: $id}}) {\n    affected_rows\n  }\n  insert_user_item_changes(objects: $items_refunds) {\n    affected_rows\n  }\n}": types.CancelSeasonAllWaitlistDocument,
    "mutation SendSeasonReleaseMessage($id: bpchar!, $message: PushMessage!) {\n  update_season_by_pk(pk_columns: {id: $id}, _append: {config: {releaseNotificationSent: true}}) {\n    id\n  }\n  sector0x {\n    create_broadcast_message(message: $message) {\n      sendno\n      msg_id\n    }\n  }\n}": types.SendSeasonReleaseMessageDocument,
    "mutation ApplyMaintenanceOperation($token_changes: [user_token_changes_insert_input!]!, $item_changes: [user_item_changes_insert_input!]!, $cabin_template_changes: [cabin_template_owned_insert_input!]!, $balance_changes: [user_balance_changes_insert_input!]!) {\n  insert_user_token_changes(objects: $token_changes) {\n    affected_rows\n  }\n  insert_user_item_changes(objects: $item_changes) {\n    affected_rows\n  }\n  insert_cabin_template_owned(objects: $cabin_template_changes, on_conflict: {constraint: cabin_template_owned_pkey, update_columns: []}) {\n    affected_rows\n  }\n  sector0x {\n    insert_user_balance_changes(objects: $balance_changes) {\n      affected_rows\n    }\n  }\n}": types.ApplyMaintenanceOperationDocument,
    "mutation InserMaintenanceLog($user_id: String!, $data: jsonb!) {\n  insert_maintenance_log_one(object: {user_id: $user_id, data: $data}) {\n    id\n  }\n}": types.InserMaintenanceLogDocument
};
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ /**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */ export function gql(source) {
    var _documents_source;
    return null != (_documents_source = documents[source]) ? _documents_source : {};
}
